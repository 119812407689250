<template>
  <div style="display: flex; flex-direction: column; background: #f6f6f6; padding-top: 50px; height: 100%; box-sizing: border-box;">
    <!-- nav -->
    <div class="header">
      <van-nav-bar
        title="万年历"
        left-arrow
        @click-left="goBack"
      />
    </div>
    <!-- /nav -->
 
    <!-- 日历 -->
    <div class="calendar" style="position: relative;">
      <van-icon name="arrow-left" @click="arrowLeft"/>
      <van-icon name="arrow" @click="arrowRieht"/>
      <van-calendar
        title="日历"
        :show-title="false"
        :poppable="false"
        :show-confirm="false"
        :style="{ width: '9.8649rem',margin: 'auto', borderRadius: '5px'}"
        :formatter="formatter"
        :min-date="minDate"
        :max-date="maxDate"
        :default-date="defaultDate"
        @select="clickDay"
      />
    </div>
    <!-- 日历 -->

    <!-- 日历详情 -->
      <div class="calendarDetail">
        <div class="calendarDetail-top">
          <p class="first-p">
            <span v-if="stringToDate(dayDetail.date).getDate()?true:false">{{ stringToDate(dayDetail.date).getDate() }}</span>
          </p>
          <div class="month">
            <p>{{ dayDetail.lunar }}</p>
            <div>
              <span>{{ dayDetail.weekday }}</span>
              <span>{{dayDetail.lunarYear}}[{{dayDetail.animalsYear}}]</span>
            </div>
          </div>
        </div>
        <div class="calendarDetail-bottom" style="font-size: 13px;">
          <div style="margin-bottom: 5px;">
            <span style="color: #00bdff;">宜</span> {{ dayDetail.suit}}
          </div>
          <div class="jihui">
            <span style="color: red;">忌</span> {{ dayDetail.avoid }}
          </div>
        </div>
      </div>
    <!-- /日历详情 -->
  </div>
</template>

<script>
import Calendar from './calendar'
import { fetchCalendarDetail } from '@/api/drivingCalendar'
export default {
  data () {
    return {
      minDate: new Date(),
      maxDate: new Date(),
      defaultDate: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      nowDay: new Date().getDate(),
      cont: 0,
      dayDetail: {},
      num: 1
    }
  },
  created () {
    this.setMinMaxDay()
    const date = `${this.year}-${this.month+1}-${this.nowDay}`
    this.fetchCalendarDetail(date)
  },
  methods: {
    stringToDate(str) {
      let tempStrs = str.split(" ")
      let dateStrs = tempStrs[0].split("-")
      let year = parseInt(dateStrs[0], 10)
      let month = parseInt(dateStrs[1], 10) - 1;
      let day = parseInt(dateStrs[2], 10);
      // let timeStrs = tempStrs[1].split(":");
      // let hour = parseInt(timeStrs [0], 10);
      // let minute = parseInt(timeStrs[1], 10);
      // let second = parseInt(timeStrs[2], 10);
      // let date = new Date(year, month, day, hour, minute, second);
      let date = new Date(year, month, day);
      // console.log(date, 'date', year, month, day)
      return date;
    },
    
    clickDay (day) {
      const _time = new Date(day)
      const y = _time.getFullYear()
      const m = _time.getMonth() + 1
      const d = _time.getDate()
      this.date = `${y}-${m}-${d}`
      this.fetchCalendarDetail(this.date)
    },

    // 当天详情
    fetchCalendarDetail (date) {
      fetchCalendarDetail({
        date
      }).then(res => {
        if (res.data.code === 0) {
          this.dayDetail = res.data.data.result.data
          if (this.dayDetail.suit) {
            this.dayDetail.suit = this.dayDetail.suit.split('.').join(' ')
          } else {
            this.dayDetail.suit = ''
          }

          if (this.dayDetail.avoid) {
            this.dayDetail.avoid = this.dayDetail.avoid.split('.').join(' ')
          } else {
            this.dayDetail.avoid = ''
          }
          console.log(this.dayDetail)
        }
      })
    },

    formatter (day) {
      const _time = new Date(day.date)
      const y = _time.getFullYear()
      const m = _time.getMonth() + 1
      const d = _time.getDate()
      const w = _time.getDay()
      const info = Calendar.solar2lunar(y, m, d)
      // const lunar = Calendar.getLunarFestival()
      // console.log(lunar, 'lunar')
      if (m == this.month + 1) {
        if (d == this.nowDay) {
          day.className = 'border'
        }
      }

      // 星期六 星期日 样式
      if (y === this.year) {
        if (w === 0 || w === 6) {
          day.className = 'weekendRed'
        }
      }

      if (m == this.month + 1) {
        if (w === 0 && d === this.nowDay || w === 6 && d === this.nowDay) {
          day.className = 'weekendRedBorder'
        }
      }
      
      // if (y === this.year) {
      //   if (m === 10) {
      //     if (d === 1 || d === 2 || d === 3 || d === 4 || d === 5 || d === 6 || d === 7) {
      //       day.topInfo = '休'
      //       day.className = 'mark'
      //     }
      //     if (d === 9) {
      //        day.topInfo = '班'
      //        day.className = 'tagNine'
      //     }
      //   } else  if (m === 5) {
      //     if (d === 1 || d === 2 || d === 3 || d === 4 || d === 5) {
      //       day.topInfo = '休'
      //       day.className = 'mark'
      //     }
      //     if (d === 8) {
      //        day.topInfo = '班'
      //        day.className = 'tagNine'
      //     }
      //   } else if (m === 4) {
      //     if (d === 3 || d === 4 || d === 5) {
      //       day.topInfo = '休'
      //       day.className = 'mark'
      //     }
      //     if (d === 25) {
      //        day.topInfo = '班'
      //        day.className = 'tagNine'
      //     }
      //   } else if (m === 1) {
      //     if (d === 1 || d === 2 || d === 3) {
      //       day.topInfo = '休'
      //       day.className = 'mark'
      //     }
      //   }

      //    // 传统节日
      //    if (y === 2021) {
      //     if (m === 2) {
      //       if (d === 11 || d === 12 || d === 13 || d === 14 || d === 15 || d === 16 || d === 17) {
      //         day.topInfo = '休'
      //         day.className = 'mark'
      //       }
      //       if (d === 7 || d === 20) {
      //         day.topInfo = '班'
      //         day.className = 'tagNine'
      //       }
      //     }
      //     if (m === 6) {
      //       if (d === 12 || d === 13 || d === 14) {
      //         day.topInfo = '休'
      //         day.className = 'mark'
      //       }
      //     }
      //     if (m === 9) {
      //       if (d === 19 || d === 20 || d === 21) {
      //         day.topInfo = '休'
      //         day.className = 'mark'
      //       }
      //       if (d === 18 || d === 26) {
      //         day.topInfo = '班'
      //         day.className = 'tagNine'
      //       }
      //     }
      //   }
      // }

      // 添加阴历 节日节气 
      if (info.festival != null && info.fextival != '') {
        day.bottomInfo = info.festival // 节日 教师节
      } else if (info.Term != null && info.Term != '') {
        day.bottomInfo = info.Term // 节气 霜降 小雪
      } else if (info.lunarFestival != null && info.lunarFestival != '') {
        day.bottomInfo = info.lunarFestival // 传统节日
      }
       else if (info.IDayCn != null && info.IdayCn != '') {
        day.bottomInfo = info.IDayCn // 阴历
      }
      return day
    },

    // 左箭头
    arrowLeft () {
      this.cont--
      if (this.cont === 0) {
        this.num = this.nowDay
      }
      this.defaultDate = new Date(
        this.year,
        this.month + this.cont,
        this.num
      )
      this.num = 1
      this.setMinMaxDay()
      const date = `${this.defaultDate.getFullYear()}-${this.defaultDate.getMonth()+1}-${this.defaultDate.getDate()}`
      this.fetchCalendarDetail(date)
    },

    // 右箭头
    arrowRieht () {
      this.cont++
      if (this.cont === 0) {
        this.num = this.nowDay
      }
      this.defaultDate = new Date(
        this.year,
        this.month + this.cont,
        this.num
      )
      this.num = 1
      this.setMinMaxDay()
      const date = `${this.defaultDate.getFullYear()}-${this.defaultDate.getMonth()+1}-${this.defaultDate.getDate()}`
      this.fetchCalendarDetail(date)
    },

    // 设置天数区间
    setMinMaxDay () {
      const firstDay = new Date(this.defaultDate)
      firstDay.setDate(1)
      this.minDate = new Date(
        this.year,
        this.month + this.cont,
        firstDay.getDate()
      )

      const endDate = new Date(this.defaultDate)
      endDate.setMonth(this.defaultDate.getMonth() + 1)
      endDate.setDate(0)
      this.maxDate = new Date(
        this.year,
        this.month + this.cont,
        endDate.getDate()
      )
    },

    // 回退
    goBack () {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isIOS) {
        window.webkit.messageHandlers.close.postMessage('up') 
      }
      if (isAndroid) {
        window.android.close()
      } 
    },

    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },

    // 添加 休 字
    mark () {
      const mark = document.getElementsByClassName('mark')
      console.log(mark[0], 'mark')
    }
  },
  mounted () {
    this.setRem()
    window.addEventListener("orientationchange", this.setRem)
    window.addEventListener("resize", this.setRem)
  },
  beforeDestroy () {
    document.documentElement.style.fontSize = "16px"
  }
}
</script>

<style  lang="less" scoped>
/deep/ .weekendRed {
  color: red;
}
/deep/ .van-calendar__day {
  font-weight: bold;
  /* width: 51px; */
  // height: 60px;
}
/deep/ .border {
  border-radius: 4px;
  height: 54px;
  // width: 1.3556rem;
  margin-top: 5px;
  background: rgba(0, 0, 0, .1);
}
/deep/ .weekendRedBorder {
  color: red;
  border: 1px solid red;
  border-radius: 4px;
  height: 53px;
  width: 1.3556rem;
  margin-top: 4px;
}
/deep/ .mark {
  color: red;
}
/deep/ .weekendRedBorder .van-calendar__bottom-info {
  bottom: 2px;
}
/deep/ .border .van-calendar__bottom-info {
  bottom: 0px;
}
/deep/ .van-calendar__header-subtitle {
  text-align: left;
  padding-left: 55px;
  line-height: normal;
  padding-top: 12px;
  box-sizing: border-box;
}
/deep/ .van-calendar__bottom-info {
  color: rgba(0, 0, 0, .4);
  font-weight: 500;
  line-height: normal;
  bottom: 4px;
}
/deep/ .van-calendar__top-info {
  color: red;
  height: 14px;
  line-height: normal;
  text-align: right;
}
/deep/ .tagNine .van-calendar__top-info {
  color: black;
}
.van-icon-arrow-left {
  position: absolute;
  top: 14px;
  left: 19px;
  font-size: 17px;
}
.van-icon-arrow {
  position: absolute;
  top: 14px;
  left: 145px;
  font-size: 17px;
}
/deep/ .van-calendar__weekdays span:nth-child(1),
/deep/ .van-calendar__weekdays span:nth-child(7) {
  color: red;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.van-nav-bar__title {
  font-size: bold;
}
.calendarDetail {
  width: 9.8649rem;
  border-radius: 5px;
  padding: 4px 15px 0px;
  margin: 8px auto;
  background: white;
  box-sizing: border-box;
  flex: 1;
}
.calendarDetail-top {
  display: flex;
  border-bottom: 1px dashed black;
  margin-bottom: 10px;
}
.first-p {
  font-size: 55px;
  margin-right: 17px;
  width: 68px;
  text-align: center;
}
.month {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.month p {
  font-size: 20px;
}
.month div {
  font-size: 18px;
  color: rgb(161, 161, 161);
  display: flex;
  justify-content: space-between;
}
/deep/ .van-calendar__day {
  position: relative;
}
/deep/ .van-calendar__selected-day {
  position: absolute;
  width: 100%;
  height: 54px;
}
/deep/ .van-calendar__selected-day .van-calendar__bottom-info {
  color: white;
  bottom: 0px;
}
/deep/ .van-calendar__selected-day .van-calendar__top-info {
  color: white;
  top: 3px;
}
.jihui {
  margin-bottom: 12px;
}
</style>