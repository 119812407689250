import { fetchGet } from '@/router/https'

// 题库
const fetchQuestion = (params = {}) => fetchGet('/mallapp/juhe/jztk/query', params )

//  对答案
const fetchAnswer = (params = {}) => fetchGet('/mallapp/juhe/jztk/answer', params)

// 万年历当天信息
const fetchCalendarDetail = (params = {}) => fetchGet('/mallapp/juhe/calendar/day', params)

export {
  fetchQuestion,
  fetchAnswer,
  fetchCalendarDetail
}